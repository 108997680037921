import React, { useState } from 'react'
import './Gallery.scss'
import Lightbox from 'react-image-lightbox';

export default function Gallery(props) {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="glr">
            <ul className="glr__list">
                {props.imgList && props.imgList.map((el, index) => {
                    return <li key={index}> <img src={el.img} alt="" onClick={() => { setIsOpen(true); setPhotoIndex(index) }} /> </li>
                })}
            </ul>

            {isOpen && (
                <Lightbox
                    enableZoom={false}
                    mainSrc={props.imgList[photoIndex].img}
                    nextSrc={props.imgList[(photoIndex + 1) % props.imgList.length].img}
                    prevSrc={props.imgList[(photoIndex + props.imgList.length - 1) % props.imgList.length].img}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + props.imgList.length - 1) % props.imgList.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % props.imgList.length)}
                />
            )}
        </div>
    )
}
