import React, { useEffect, useState } from 'react'
//  SwiperJS
import { Swiper, SwiperSlide } from 'swiper/react'
//  Components
import OutlineText from '../OutlineText/OutlineText';
import Speaker from '../Speaker/Speaker';
//  Styles
import './Speakers.scss'

export default function Speakers(props) {
    const [showSwiper, setShowSwiper] = useState(false);

    let swiperInterval = false; // Used to debounce

    /**
     * Check if screen's width is below 768 to show swiper
     */
    function checkSwiper() {
        if (window.innerWidth >= 768) {
            setShowSwiper(false);
        } else {
            setShowSwiper(true);
        }
    }

    /**
     * Check swiper when window is resized
     */
    window.addEventListener('resize', () => {
        clearTimeout(swiperInterval);
        swiperInterval = setTimeout(checkSwiper, 500);
    });

    useEffect(() => {
        checkSwiper(); // Check swiper at component mounted
        return () => {
            clearTimeout(swiperInterval);
        }
    }, [swiperInterval]);

    // Swiper element - For small screens
    const speakersSwiper = <Swiper
        loop={true}
        spaceBetween={30}
        slidesPerView={2}
        centeredSlides={true}
        breakpoints={{
            600: {
                slidesPerView: 3
            }
        }}
    >
        {props.list && props.list.map(function (spkr, i) {
            return <SwiperSlide key={i}><Speaker data={spkr} /></SwiperSlide>;
        })}
    </Swiper>;

    // List element - For large screens
    const speakersList = <ul className="spkrs__content__list">
        {props.list && props.list.map(function (spkr, i) {
            return <li key={i} className="spkrs__content__list__item"><Speaker data={spkr} /></li>;
        })}
    </ul>;


    return (
        <div className={`spkrs ${props.viewMode ? ' spkrs--' + props.viewMode : ''}`} id="speakers">
            {props.children ? props.children :
                <div className="spkrs__head container">
                    <h3 className="spkrs__head__title">
                        <OutlineText
                            text="SPEAKERS"
                            fill={true}
                            color="green"
                            stroke={0.35}
                        />
                    </h3>
                    <p className="spkrs__head__txt">
                        La edición 2020 de Patagonian Conf está dedicada a reflexionar sobre los nuevos perfiles en la industria y las tecnologías más destacadas.
                        ¿Cuáles son las especialidades profesionales más requeridas? ¿Cómo se adquieren los nuevos conocimientos? ¿Cuáles son las tecnologías más utilizadas? ¿Es posible reconvertirse? ¿Qué lugar ocupan en las organizaciones?
                        Vamos a conversar y aprender con especialistas sobre la evolución del talento en el mundo.
                    </p>
                </div>
            }
            {props.list ?
                <div className="spkrs__content">
                    {showSwiper ? speakersSwiper : speakersList}
                </div>
                : 'No speakers added...'
            }
        </div>
    )
}
