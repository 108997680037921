import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import ReactTooltip from 'react-tooltip';

import './ComingSoon.scss';
import patagonianConfImg from '../../res/img/coming-soon/Logo.png';
import revolutionImg from '../../res/img/2022/coming-soon/revolution.png';
import fintechImg from '../../res/img/2022/coming-soon/fintech.png';
import comingSoonImg from '../../res/img/2022/coming-soon/coming-soon.png';
import firstDotImg from '../../res/img/2022/coming-soon/first_circle.png';
import secondDotImg from '../../res/img/2022/coming-soon/second_circle.png';
import thirdDotImg from '../../res/img/2022/coming-soon/third_circle.png';
import purpleImg from '../../res/img/2022/coming-soon/purple_circle.png';
import greenImg from '../../res/img/2022/coming-soon/green_circle.png';
import yellowImg from '../../res/img/2022/coming-soon/yellow_circle.png';
import purpleTwoImg from '../../res/img/2022/coming-soon/purple_circle_2.png';
import greenTwoImg from '../../res/img/2022/coming-soon/green_circle_2.png';
import argentinaImage from '../../res/img/conf-2021/argentina.png';
import colombiaImage from '../../res/img/conf-2021/colombia.png';
import { ENVIROMENT } from '../../res/utils/env';

// Random component
const Completionist = () => <></>;

// Renderer countdown timer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
        <div className="countdown__main">
            <div className="countdown__item">
                <p className="countdown__number">{days}</p>
                <p className="countdown__text">DÍAS</p>
            </div>
            <p className="colon">.</p>
            <div className="countdown__item">
                <p className="countdown__number">{hours}</p>
                <p className="countdown__text">HORAS</p>
            </div>
            <p className="colon">.</p>
            <div className="countdown__item">
                <p className="countdown__number">{minutes}</p>
                <p className="countdown__text">MINUTOS</p>
            </div>
            <p className="colon">.</p>
            <div className="countdown__item">
                <p className="countdown__number">{seconds}</p>
                <p className="countdown__text">SEGUNDOS</p>
            </div>
        </div>
    );
  }
};

// Custom Functions
const hubSpotConnection = async (formData, IP) => {
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${ENVIROMENT.PORTAL_HUBSPOT_ID}/${ENVIROMENT.FIRST_CONF_2022_FORM_ID}`;

    // hsCookie gets the data necessary to track Hubspot analytics
    const hsCookie = document.cookie.split(';').reduce((cookies, cookie) => {
        const [ name, value ] = cookie.split('=').map((c) => c.trim());
        if (name.includes('utm')) cookies[name] = value;
        return cookies;
    });
    
    const utmCampaignCookie = localStorage.getItem('utm_campaign');
    const utmSourceCookie = localStorage.getItem('utm_source');
    const utmMediumCookie = localStorage.getItem('utm_medium');
    const hubspotData = [
        { name: 'email', "value": formData.email },
        { name: 'ip_location', "value": IP },
        { name: 'utm_campaign', "value": utmCampaignCookie ? utmCampaignCookie : 'none' },
        { name: 'utm_source', "value": utmSourceCookie ? utmSourceCookie : 'none' },
        { name: 'utm_medium', "value": utmMediumCookie ? utmMediumCookie : 'none' },
    ];

    const dataForSent = {
        fields: hubspotData,
        context: {
            hutk: hsCookie.hubspotutk,
            pageUri: `https://patagonianconf.com/`,
            pageName: `Patagonian Conference`,
            ipAddress: `${IP}`,
        }
    };
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(dataForSent),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then((res) => res.json())
        .catch((error) => console.error('error: ', error));
};

const ComingSoon = () => {
    const [isSuccessfully, setIsSuccessfully] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);
    let inputRef = false;
    const [ IP, setIP ] = useState('');
    const withCountdown = true;

    useEffect(() => {
        fetchIP();
    }, []);
    // Get IP address from client for Hubspot analytics
    const fetchIP = async () => {
        const res = await fetch('https://ip.nf/me.json');
        res.json().then((response) => setIP(response.ip.ip)).catch((err) => console.log(err));
    }
    const sendOnClick = async () => {
        const el = document.getElementById('email');
        if (el.value) {
            const emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            const emailValue = el.value;
            if (emailRegex.test(emailValue)) {
                try {
                    const data = { email: emailValue };
                    const responseFetch = await hubSpotConnection(data, IP);
                    if (responseFetch.inlineMessage) {
                        setIsSuccessfully(true);
                        setShowTooltip(true);
                    }
                } catch (error) {
                    console.error('error from conference submission:', error);
                    setIsSuccessfully(true);
                }
            } else {
                ReactTooltip.show(inputRef);
                setShowTooltip(false);
            }
        } else {
            ReactTooltip.show(inputRef);
            setShowTooltip(false);
        }
    };

    const ShowForm = () => isSuccessfully ? (
        <>
            <p className="success-message">¡Gracias por agendar! Pronto nos contactaremos contigo.</p>
        </>
    ) : (
        <>
            <input
                type="email"
                id="email"
                name="email"
                placeholder={showTooltip ? 'TU EMAIL' : 'Ingresa un correo electrónico válido'}
                minLength="4"
                title="Dirección de correo donde te avisaremos todo lo relacionado a PatagonianConf 2022"
                ref={ref => inputRef = ref}
                data-tip="Por favor ingresa un correo electrónico válido"
                autoComplete="off"
            />
            <div className="coming-soon__link--button" onClick={sendOnClick}><span>NOTIFICARME</span></div>
            <ReactTooltip disable={showTooltip} />        
        </>
    );

    return (
        <div className="coming-soon__main-container">
            <div className="first__bg__dot">
                <img src={firstDotImg} alt="first dot" />
            </div>
            <div className="coming-soon__main">
                <div className="little-circle">
                    <img src={purpleImg} alt="little circle" />
                </div>
                <div className="little-circle">
                    <img src={greenImg} alt="little circle" />
                </div>
                <div className="little-circle">
                    <img src={yellowImg} alt="little circle" />
                </div>
                <img src={patagonianConfImg} className="coming-soon__logo" alt="Patagonian conf 2022" />
                <div className="coming-soon-container coming-soon__revolution">
                    <img src={revolutionImg} className="coming-soon__revolution--img" alt="revolution Patagonian conf 2022" />
                </div>
                <div className="coming-soon-container coming-soon__fintech">
                    <img src={fintechImg} className="coming-soon__fintech--img" alt="fintech Patagonian conf 2022" />
                </div>
                <div className="coming-soon-container coming-soon__img">
                    <img src={comingSoonImg} className="coming-soon__img--img" alt="coming soon Patagonian conf 2022" />
                </div>
                {withCountdown ? (
                    <>
                    <div className="countdown_timer__main">
                        <Countdown
                            date={new Date('November 17, 2022 14:00:00')}
                            renderer={renderer}
                        />
                    </div>
                    <div className="twenty-two-header__main__countries mt-4 center-flex">
                        <div className="twenty-two-header__main__countries__item">
                        <p className="twenty-two-header__main__countries__label">17 NOV 2022 · 2:00 PM</p>
                        <img className="twenty-two-header__main__countries__img" src={colombiaImage} alt="Colombia" />
                        </div>
                        <div className="twenty-two-header__main__countries__item">
                        <p className="twenty-two-header__main__countries__label">4:00 PM</p>
                        <img className="twenty-two-header__main__countries__img" src={argentinaImage} alt="Argentina" />
                        </div>
                    </div>
                    </>
                    
                    
                ) : (<></>)}
                    <div className={`mt-4 coming-soon__link${isSuccessfully ? ' mt-4 coming-soon__link--completed ' : ''}`}>
                        <ShowForm />
                    </div>
                
                <div className="coming-soon-description">
                    <p>La conferencia anual de tecnología e innovación de Patagonian se acerca a una nueva edición.
                        Suscríbete para informarte de las novedades.</p>
                </div>
                <div className="little-circle-two">
                    <img src={greenTwoImg} alt="little circle" />
                </div>
                <div className="little-circle-three">
                    <img src={purpleTwoImg} alt="little circle" />
                </div>
            </div>
            <div className="second__bg__dot">
                <img src={secondDotImg} alt="first dot" />
            </div>
            <div className="third__bg__dot">
                <img src={thirdDotImg} alt="first dot" />
            </div>
        </div>
    );
};

export default ComingSoon;