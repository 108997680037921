import React from 'react'
import { Memories } from '../../components/Memories/Memories'
import NextEdition from '../../components/NextEdition/NextEdition'

import bg_1 from '../../res/img/speakers/bg_1.png'
import bg_2 from '../../res/img/speakers/bg_2.png'
import bg_3 from '../../res/img/speakers/bg_3.png'
import bg_4 from '../../res/img/speakers/bg_4.png'
import bg_5 from '../../res/img/speakers/bg_5.png'
import bg_6 from '../../res/img/speakers/bg_6.png'
import line from '../../res/img/prev-editions/line_17.png'
import usr_1 from '../../res/img/speakers/usr_1.png'
import usr_2 from '../../res/img/speakers/usr_2.png'
import usr_10 from '../../res/img/speakers/usr_10.png'
import usr_4 from '../../res/img/speakers/usr_4.png'
import usr_5 from '../../res/img/speakers/usr_5.png'
import usr_8 from '../../res/img/speakers/usr_8.png'
import usr_11 from '../../res/img/speakers/usr_11.png'
import usr_12 from '../../res/img/speakers/usr_12.png'
import usr_13 from '../../res/img/speakers/usr_13.png'
import usr_14 from '../../res/img/speakers/usr_14.png'
import usr_15 from '../../res/img/speakers/usr_15.png'

export default function Twentieh() {
    const speakers = [
        { name: "Martina Rua", job: "Periodista", role: "host", img: usr_1, colorBox: bg_1, url: "https://www.linkedin.com/in/martinarua/" },
        { name: "Gustavo Arjones", job: "Ank CTO", color: "green", role: "speaker", img: usr_2, colorBox: bg_2, url: "https://www.linkedin.com/in/arjones/" },
        { name: "Rodrigo Falcó", job: "Patagonian CTO", color: "blue", role: "speaker", img: usr_10, colorBox: bg_3, url: "https://www.linkedin.com/in/rodrigofalco/", paddingLeft: "9px" },
        { name: "Rudi Borrmann", job: "Lead OGP", role: "speaker", color: "violet", img: usr_4, colorBox: bg_4, url: "https://www.linkedin.com/in/rudiborrmann/", paddingLeft: "-11px" },
        { name: "Eugenio Diaz Lis", job: "Patagonian CBO", role: "speaker", img: usr_5, colorBox: bg_5, url: "https://www.linkedin.com/in/elshuje/" },
        { name: "Lucía Suarez", job: "Global Talent Management Leader at P&G", role: "speaker", color: "green", img: usr_12, colorBox: bg_2, url: "https://www.linkedin.com/in/luciamariasuarez/", addClass: "whitespace-break", paddingLeft: "10px" },
        { name: "Gustavo Brey", color: "blue", colorBox: bg_3, job: "Ingenia Co-Founder & CGO", role: "speaker", img: usr_13, url: "https://www.linkedin.com/in/gbrey/", addClass: "whitespace-break", paddingLeft: "18px" },
        { name: "Betina García", job: "UX Lead, Google Design Sprint Master", role: "Guest", color: "violet", img: usr_8, colorBox: bg_6, url: "https://www.linkedin.com/in/betinagarcia/", addClass: "whitespace-break", paddingLeft: "5px" },
        { name: "Jason Whittet", job: "Digital Innovation Lead at Amazon", role: "Guest", img: usr_11, colorBox: bg_1, url: "https://www.linkedin.com/in/jasonwhittet/", addClass: "whitespace-break", paddingLeft: "10px" },
        { name: "Melina Masnatta", job: "Director & Co-Funder de Chicas en Tecnología", role: "Guest", color: "green", img: usr_14, colorBox: bg_2, url: "", addClass: "whitespace-break", paddingLeft: "8px" },
        { name: "Noel Alvarez", job: "Communications Coordinator at Arbusta", color: "blue", role: "Guest", img: usr_15, colorBox: bg_3, url: "", addClass: "whitespace-break", paddingLeft: "14px" },
    ];

    return (
        <>
            <NextEdition
                actualEdition={{ edition: '2020', color: 'blue' }}
                changeToEdition={{ edition: '2021', color: 'orange' }}
                title={{ regular: "La evolución del", highlight: 'talento', img: line }}
                speakers={speakers}
                hideForm
            />
            <Memories />
        </>
    )
}
