import React from 'react';

const TestingYTLive = () => {

    return (
        <div style={{ padding: "270px 200px" }}>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/jfKfPfyJRdk?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <iframe width="560" height="315" src={`https://www.youtube.com/live_chat?v=jfKfPfyJRdk&embed_domain=uat.patagonianconf.com`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    );
};

export default TestingYTLive;