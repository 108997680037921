import React from 'react';
//  SwiperJS
import { Swiper, SwiperSlide } from 'swiper/react';
import HubspotForm from 'react-hubspot-form';
import './TwentyTwo.scss'
import { ENVIROMENT } from '../../res/utils/env';
//  Images
import firstDotImg from '../../res/img/2022/coming-soon/first_circle.png';
import secondDotImg from '../../res/img/2022/green-2.webp';
import lastDotImg from '../../res/img/2022/purple.webp';
import laptopImage from '../../res/img/2022/laptop.png';
import joystickImage from '../../res/img/conf-2021/joystick.png';
import liveImg from '../../res/img/2022/revivelo-aqui.png';
import memoriesTextImage from '../../res/img/conf-2021/memories.svg';
import microphoneImage from '../../res/img/2022/mic.png';
import litecoinOneImage from '../../res/img/2022/liteCoin.png';
import litecoinTwoImage from '../../res/img/2022/litecoin-2.svg';
import litecoinThreeImage from '../../res/img/2022/litecoin-3.svg';
import looperImage from '../../res/img/2022/looper.svg';
import comingSoonLogo from '../../res/img/coming-soon/logo-footer.svg';

//Circle images
import seventhCircle from '../../res/img/2022/sphere.svg';
//Speaker images
import firstSpeaker from '../../res/img/2022/speakers/martina_rua.png';
import secondSpeaker from '../../res/img/2022/speakers/victoria_guareschi.png';
import thirdSpeaker from '../../res/img/2022/speakers/diego_burgos.png';
import fourthSpeaker from '../../res/img/2022/speakers/gustavo_brey.png';
import fifthSpeaker from '../../res/img/2022/speakers/lucas_paus.png';
import sixthSpeaker from '../../res/img/2022/speakers/ivan_dalonso.png';
import seventhSpeaker from '../../res/img/2022/speakers/rodrigo_falco.png';
import eigthSpeaker from '../../res/img/2022/speakers/mar_fernandez.png';
// Sponsor images
import ingeniaImg from '../../res/img/2022/sponsors/gold/Ingenia.png';
import invapImg from '../../res/img/2022/sponsors/gold/Invap.svg';
import modoImg from '../../res/img/2022/sponsors/gold/Modo.svg';

import fourthSponsor from '../../res/img/conf-2021/sponsors/rio_negro.png';
import sixthSponsor from '../../res/img/2022/sponsors/silver/Payoneer.png';
import fitfhSponsor from '../../res/img/2022/sponsors/bronze/primary.png';

/**
 * LINE UP TIME LINE INFO LIST
 */
const LINEUP_ITEMS = [
  {
    title: 'Rodrigo Falcó',
    helpText: `CTO de Patagonian`,
    secondaryHelpText: `“Hello Fintech World!”`,
    description: `Introducción al universo Fintech. Rodrigo nos ofrecerá un acercamiento al mundo de los servicios tecnológicos financieros.`
  },
  {
    title: 'Diego Burgos',
    helpText: 'CTO de Pomelo',
    secondaryHelpText: `“La base del camino”`,
    description: `Desde su experiencia como creador del equipo IT de Pomelo, Diego nos contará cómo sentar las bases para que cualquier startup de tecnología pueda encender motores y despegar.`
  },
  {
    title: 'Victoria Guareschi',
    helpText: 'Product Lead de Lemon',
    secondaryHelpText: `“Producto y comunidad: evolución hacia web3”`,
    description: `Pasos para la construcción de un producto que aporte valor a la comunidad: plantear una visión, escuchar feedback e iterar. En esta charla, Vicky cuenta el desarrollo del producto que lanzó Lemon para acercar web3 a más de 1.500.000 personas.`
  },
  {
    title: 'Lucas Paus',
    helpText: 'CISO de Modo',
    secondaryHelpText: `“El Desafío de crear una fintech tan segura como simple”`,
    description: `Cuáles son los principales desafíos en materia de seguridad es fundamental para mantener la integridad y disponibilidad de nuestros servicios. A lo largo de la disertación, nos introduciremos en las problemáticas actuales y de qué manera robustecer nuestros sistemas.`
  },
  {
    title: 'Panel: Gustavo Brey - Mar Fernández - Iván Dalonso',
    helpText: 'Co-founder & CGO de Ingenia - VP GTM de Payoneer Latam - CBO de Primary',
    secondaryHelpText: `“¿Hacia dónde van las Fintechs?"`,
    description: `Sinergia y disrupción. Un panel de expertos se reunirá a conversar sobre cómo las Fintech le está dando forma al sistema financiero global. Cuáles son sus desafíos tecnológicos, cómo es la situación en Latinoamérica respecto a la adopción de las herramientas y cómo será la evolución de los productos y servicios. `
  },
]

/**
 * MEMORIES LIST
 */
const MEMORIES = [
  { url: 'qjrEE0I7yJQ', alt: 'video' },
]

const BRONZE_SPONSORS = [
  { img: fitfhSponsor, name: 'Primary', url: 'https://primary.com.ar/' },
];

const SILVER_SPONSORS = [
  { img: fourthSponsor, name: 'río negro', url: 'https://www.rionegro.com.ar/' },
  { img: sixthSponsor, name: 'río negro', url: 'https://www.payoneer.com/' },
];

const GOLD_SPONSORS = [
  { img: ingeniaImg, name: 'Ingenia', url: 'https://ingenia.la/' },
  { img: invapImg, name: 'Invap', url: 'https://www.invap.com.ar/' },
  { img: modoImg, name: 'Modo', url: 'https://www.modo.com.ar/' },
];

/**
 * SPEAKERS INFO LIST
 */
const SPEAKERS = [
  { img: seventhSpeaker, name: 'RODRIGO FALCO', description: 'CTO · Patagonian'},
  { img: thirdSpeaker, name: 'DIEGO BURGOS', description: 'CTO · Pomelo'},
  { img: secondSpeaker, name: 'VICTORIA GUARESCHI', description: 'Product Lead · Lemon'},
  { img: fifthSpeaker, name: 'LUCAS PAUS', description: 'CISO · Modo'},
  { img: fourthSpeaker, name: 'GUSTAVO BREY', description: 'Co-founder & CGO · Ingenia'},
  { img: eigthSpeaker, name: 'MAR FERNÁNDEZ', description: 'VP GTM · Payoneer Latam'},
  { img: sixthSpeaker, name: 'IVÁN DALONSO', description: 'CBO · Primary'},
];

/**
 * Images after conf footer container
 */
 const AfterConfFooter = () => {
  return (
    <div className="after-conf-footer__main">
      <img src={joystickImage} className="after-conf-footer__image--first" alt="Laptop" />
      {/*<img className="after-conf-footer__image--second" src={plusImage} alt="plus" />*/}
    </div>
  );
};

/**
 * Footer component of the conf
 */
 const Footer = () => {
  return (
      <div className="footer-brand footer-brand--from-home">
        <div className="container">
          <div className="footer-brand__content">
            <p>Powered by</p>
            <a className="footer-brand__content__link" href="https://patagonian.com/" target="_blank" rel="noopener noreferrer">
              <img
                className="footer-brand__content__link__logo"
                alt="Patagonian"
                src={comingSoonLogo}
              />
            </a>
          </div>
        </div>
      </div>
  )
};

/**
 * Header component of the conf
 */
const Header = () => {
  const VIDEO_ID = 'OWxDXmykLG4';
  return (
    <div className="live-header__main">
      <div className="live-header-img__container">
        <img src={liveImg} alt="live now" />
      </div>
      <div className="live__container">
        <iframe src={`https://www.youtube.com/embed/${VIDEO_ID}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  );
};


/**
 * Line up main container
 * @returns the line up main container rendered
 */
const LineUpContainer = () => {

  return (
    <div className="twenty-two-lineup__container__main" id="twenty-two-lineup">
      <div className="twenty-two-lineup__main__images">
        <div className="line-up__title__main">
          <div className="line-up__divider__line" />
          <p>LINE UP</p>
        </div>
        <img src={laptopImage} className="twenty-two-lineup__image--second" alt="Laptop" />
      </div>
      <div className="twenty-two-lineup__main__content">
        <img className="circle__header circle__header--seventh" src={seventhCircle} alt="seventhCircle cirlce" />
        <div className="twenty-two-lineup__main">
          {/*
          <div className="twenty-two-lineup__description__main">
            <div className="twenty-two-lineup__description__text">
              Este año el encuentro se realizará de manera remota, en vivo, en una transmisión de acceso gratuito. La misión es la de siempre: Aprender.  
            </div>
          </div>
          */}
          <div className="twenty-two-lineup__timeline__main">
            <img className="twenty-two-lineup__timeline__plus_img twenty-two-lineup__timeline__plus_img--first" src={litecoinOneImage} alt="litecoin one" />
            <img className="twenty-two-lineup__timeline__plus_img twenty-two-lineup__timeline__plus_img--second" src={litecoinTwoImage} alt="litecoin two" />
            <img className="twenty-two-lineup__timeline__plus_img twenty-two-lineup__timeline__plus_img--third" src={litecoinThreeImage} alt="litecoin three" />
            {LINEUP_ITEMS.map(({ title, helpText, secondaryHelpText, description }, index) => (
              <LineUpItem secondaryHelpText={secondaryHelpText} helpText={helpText} key={index} title={title} description={description} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Line up item
 * @param helpText description of the lineup item
 * @param title title of the lineup item
 * @returns the line up item component
 */
const LineUpItem = ({
  helpText,
  title,
  secondaryHelpText,
  description,
}) => (
  <div className="twenty-two-lineup__timeline__item">
    <p className="twenty-two-lineup__item__title">{title}</p>
    {helpText && (<p className="twenty-two-lineup__item__help_text">{helpText}</p>)}
    {secondaryHelpText && (<p className="twenty-two-lineup__item__help_text twenty-two-lineup__item__help_text--secondary">{secondaryHelpText}</p>)}
    <p className="twenty-two-lineup__item__help_text twenty-two-lineup__item__help_text--description">{description}</p>
  </div>
);

/**
 * Main container of memories section
 */
const MemoriesContainer = ({ isAfterConf }) => {
  return (
    <div className="speakers-twentytwo__main" id="memories">
      <div className="speakers-twentytwo__content">
        <div className="speakers-twentytwo__main__images">
          <img src={memoriesTextImage} className="memories__image--first" alt="Sponsors text" />
          <img src={laptopImage} className="memories__image--second" alt="Laptop" />
        </div>
        {!isAfterConf && (
          <div className="first_speaker__main">
            <SpeakerAvatar avatar={firstSpeaker} name="MARTINA RUA" description="Host" />
          </div>
        )}
        <div className="memories__main">
          {MEMORIES.map(({ url, alt }, i) => (
            <div className="memories__main__video" key={i}>
              <iframe src={`https://www.youtube.com/embed/${url}`}
                title={`${alt}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * Speaker avatar item
 * @param avatar speaker image url
 * @param name name of the speaker
 * @param description the short description of the speaker
 * @returns the speaker avatar component
 */
const SpeakerAvatar = ({
  avatar,
  name,
  description,
}) => {
  return (
    <div className="speakers-twentytwo__avatars__item">
        <img className="speakers-twentytwo__avatars__img" src={avatar} alt="speaker" />
        <div className="speakers-twentytwo__avatars__text">
          <p className="speakers-twentytwo__avatars__label">{name}</p>
          <p className="speakers-twentytwo__avatars__description">{description}</p>
        </div>
    </div>
  );
}

/**
 * Main container of speaker section
 */
const SpeakersContainer = ({ isAfterConf }) => {
  return (
    <div className="speakers-twentytwo__main" id="speakers-twentytwo">
      <div className="speakers-twentytwo__content">
        <div className="speakers-twentytwo__main__images">
          <img src={microphoneImage} className="speakers-twentytwo__image--first" alt="Microphone" />
          <div className="speakers-text__main">
            <p className="speakers-twentytwo-text">SPEAKERS</p>
            <div className="speakers-line" />
          </div>
        </div>
        <div className="first_speaker__main">
          <SpeakerAvatar avatar={firstSpeaker} name="MARTINA RUA" description="Host" />
          {isAfterConf && (
            <p className="description__text description__text--speakers-twentytwo">La edición 2022 será un “Click al futuro”. Dedicaremos el espacio a reflexionar sobre nuevas tecnologías. ¿Cuáles son las tendencias en la industria? ¿Hacia dónde vamos? ¿Qué conocimientos se requieren? ¿Estamos listos para dar el salto?</p>
            )}
        </div>
        <div className="speakers-twentytwo__mobile">
          <Swiper
            loop={true}
            spaceBetween={30}
            slidesPerView={2}
            centeredSlides={true}
            breakpoints={{
                600: {
                    slidesPerView: 3
                }
            }}
          >
            {SPEAKERS && SPEAKERS.map(({ img, name, description }, i) => {
                return (
                  <SwiperSlide key={i}>
                    <SpeakerAvatar avatar={img} name={name} description={description} />
                  </SwiperSlide>
                );
            })}
        </Swiper>
        </div>
        <div className="speakers-twentytwo__avatars__main">
          {SPEAKERS.map(({ img, name, description }, index) => index < 3 && (
            <SpeakerAvatar key={name} avatar={img} name={name} description={description} />
          ))}
        </div>
        <div className="speakers-twentytwo__avatars__main speakers-twentytwo__avatars__main--second">
          {SPEAKERS.map(({ img, name, description }, index) => index > 2 && (
            <SpeakerAvatar key={name} avatar={img} name={name} description={description} />
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * Sponsor item component
 */
const SponsorItem = ({
  image,
  name,
  url,
  type,
}) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img className={`twenty-two-sponsors__img twenty-two-sponsors__img--${type}`} src={image} alt={name} />
    </a>
  );
};

/**
 * Sponsor main component
 */
const SponsorContainer = ({ sponsors = [], type = 'gold' }) => {
  const sponsorType = type === 'gold' ? 'gold' : type === 'silver' ? 'silver' : 'bronze';
  return (
    <div className="twenty-two-sponsors__content">
      <p className={`sponsors__title sponsors__title--${sponsorType}`}>{sponsorType.toUpperCase()} SPONSORS</p>
      <div className={`sponsors__main sponsors__main--${sponsorType}`}>
        {sponsors.map(({ img, name, url }, index) => (
          <SponsorItem key={index} image={img} name={name} url={url} type={type} />
        ))}
      </div>
    </div>
  );
};

// Main component
const TwentyTwo = () => {
  const isAfterConf = false;

  return (
    <>
      <div className="twenty-two">
        <div className="first__bg__dot">
          <img src={firstDotImg} alt="first dot" />
        </div>
        <div className="second__bg__dot">
          <img src={lastDotImg} alt="second dot" />
        </div>
        <div className="twenty-two__container">
          <Header isAfterConf={isAfterConf} />
          <div className="description__container">
            <div className="twenty-two-description__content">
              <p className="description__text description__text--conf">
                Patagonian Conf es un evento anual que busca divulgar temáticas de tecnología e innovación. Invitamos a especialistas de distintas disciplinas a compartir sus conocimientos y sumar valor a la comunidad tecnológica, en una transmisión de acceso gratuito.
                <br />
                <br />
                En esta edición, con <b>“Revolución Fintech”</b>, hicimos foco en los nuevos servicios financieros. Presentamos a los protagonistas de una industria que está en pleno proceso disruptivo y conocimos las principales tecnologías que están cambiando al mundo.
                <br />
                <br />
                <b>¡Muchas gracias por participar!</b>
              </p>
            </div>
          </div>
        </div>
        <div className="twenty-two-looper__main">
          <img className="circle__header circle__header--eight" src={seventhCircle} alt="eight cirlce" />
          <img className="twenty-two-looper-img" src={looperImage} alt="looper tweny two" />
        </div>
        <div className="twenty-two__container" id="speakers">
          <SpeakersContainer isAfterConf={isAfterConf} />
          {isAfterConf ? (
            <>
            <div className="empty__space empty__space--third"></div>
              <MemoriesContainer isAfterConf={isAfterConf} />
              <AfterConfFooter />
            </>
          ) : (
            <LineUpContainer />
            )}
          {!isAfterConf && (
            <>
              <div className="sponsors__container__main" id="sponsors">
                <div className="sponsors__title__main">
                  <p>SPONSORS</p>
                  <div className="sponsors__divider__line" />
                </div>
                <SponsorContainer sponsors={GOLD_SPONSORS} />
                <SponsorContainer sponsors={SILVER_SPONSORS} type="silver" />
                <SponsorContainer sponsors={BRONZE_SPONSORS} type="bronze" />
              </div>
            </>
          )}
          <div className="hubspot__form" id="hubspot__form">
            <p className="hubspot__form__title">Mantente informado</p>
            <p className='description__text hubspot__form__subtitle'>La Patagonian Conf ya terminó pero completa el siguiente formulario para seguir informado.</p>
            <div className="sign_up__form">
              <HubspotForm
                portalId={ENVIROMENT.PORTAL_HUBSPOT_ID}
                formId={ENVIROMENT.CONF_2022_FORM_ID}
                cssClass='hs-form'
                locale='en'
                region='na1'
              />
            </div>
          </div>
          <div className="empty__space empty__space--second"></div>
          <Footer />
        </div>
      </div>
      <div className="footer__bg__main">
        <div className="last__bg__dot">
          <img src={secondDotImg} alt="last dot" />
        </div>
      </div>
    </>
  );
};

export default TwentyTwo;
