import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import FooterBrand from '../FooterBrand/FooterBrand';
import Header from '../Header/Header';
import Nineteen from '../../pages/Nineteen/Nineteen'
import Seventeen from '../../pages/Seventeen/Seventeen'
import Twentieh from '../../pages/Twentieth/Twentieth'
import TwentyOne from '../../pages/TwentyOne/TwentyOne'
import Home from '../../pages/Home/Home';
import TwentyTwo from '../../pages/TwentyTwo/TwentyTwo';
import TestingYTLive from '../../pages/TestingYTLive';

export default function App() {

    useEffect(() => {
        // ReactGTM
        const tagManagerArgs = {
            gtmId: 'GTM-KT9GQ8W',
        };
        TagManager.initialize(tagManagerArgs);
    }, []);

    return (
        <BrowserRouter>
            <Header />
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/edicion-2022' component={TwentyTwo} />
                <Route exact path='/edicion-2021' component={TwentyOne} />
                <Route exact path='/edicion-2020' component={Twentieh} />
                <Route exact path='/edicion-2019' component={Nineteen} />
                <Route exact path='/edicion-2017' component={Seventeen} />
                <Route exact path="/testing-yt" component={TestingYTLive} />
            </Switch>
            <FooterBrand />
        </BrowserRouter>
    )
}
