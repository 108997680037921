import React, { useEffect, useState } from 'react'
import './Memories.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import FloatingText from '../FloatingText/FloatingText';
import line from '../../res/img/prev-editions/line_17.png'
import memoriesTxt from '../../res/img/prev-editions/memories.svg'

export const Memories = () => {
    const [showSwiper, setShowSwiper] = useState(false);
    const videos = [
        { url: 'wZu_FoP_gOQ' },
        { url: '5VGtAajjgC4' },
        { url: 'W_EBxiLjy0Y' },
        { url: 'y74d58OhSYM' },
        { url: 'tSZHJwQg8KQ' },
        { url: 'GlMWsPGsw9k' },
        { url: 'IFjcVVlykbY' },
        { url: 'A_ZTBq6qTDk' },
        { url: 'iSWdURhGsjI' }
    ];

    /**
    * Hook to add resize listener
    */
    useEffect(() => {
        const onResize = () => {
            if (window.innerWidth < 768) {
                setShowSwiper(true);
            } else {
                setShowSwiper(false);
            }
        }
        window.addEventListener('resize', onResize); // Trigger it on resize event
        onResize(); // Trigger it first time
        return () => { window.removeEventListener('resize', onResize); } // Clean to avoid memory leak
    }, [showSwiper]);

    const mrsSwiper = <Swiper
        loop={true}
        spaceBetween={8}
        slidesPerView={2}
        centeredSlides={true}
        breakpoints={{
            600: {
                spaceBetween: 16
            }
        }}>
        {videos.map((video, index) => <SwiperSlide key={index}>
            <div className="mrs__swiper__item">
                <iframe src={`https://www.youtube.com/embed/${video.url}`}
                    title={`Video ${index}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </div>
        </SwiperSlide>)
        }
    </Swiper>;

    const mrsGrid = <div className="conference_memories__gallery">
        {videos.map((video, index) => (
            <div className="conference_memories__gallery__video" key={index}>
                <iframe src={`https://www.youtube.com/embed/${video.url}`}
                    title={`Video ${index}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </div>)
        )}
    </div>;

    return (
        <div className="mrs" id="memories">
            <div className="mrs__fl-line">
                <img className="mrs__fl-line__img" src={line} alt="Background" />
            </div>
            <div className="container">
                <FloatingText times={3} img={memoriesTxt} altText="Memories" />
                {showSwiper ? mrsSwiper : mrsGrid}
            </div>
        </div>
    )
}