import React from 'react'
import './FloatingText.scss'

/**
 * Gets a text and display it a certain number of times
 * 
 * @param {*} props Used to bring the text image and the alternative text
 */
export default function FloatingText(props) {
    return (
        <div className="fl-txt">
            {[...Array(props.times)].map((e, i) => {
                return <img className="fl-txt__item" key={i} src={props.img} alt={props.altText} />
            })}
        </div>
    )
}
