import React, { useEffect, useState } from 'react'
import './Header.scss'
//  Components
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useLocation } from 'react-router';
//  Images
import { ReactComponent as Logo } from '../../res/img/logo-conf.svg';
import { ReactComponent as LogoRemoteEdition } from '../../res/img/coming-soon/Logo-amarillo.svg'
import arrow from '../../res/img/arrow.svg'
import arrowHome from '../../res/img/conf-2021/arrow_white.svg';

export default function Header() {
    const location = useLocation();
    const [lastScroll, setLastScroll] = useState(0); // State to store current scroll position
    const [scrollClass, setScrollClass] = useState(''); // State for scroll-up show
    const [showSubmenu, setShowSubmenu] = useState(false);
    const [showLogo, setShowLogo] = useState(false);
    const [isFromHome, setIsFromHome] = useState(false);
    const path = location.pathname ? location.pathname : '';

      // Custom Functions
  /**
   * Hook to add scroll listener and show menu on scroll up
   */
    useEffect(() => {
        const offset = 110; // Should be same as navbar's height
        const onScroll = () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= offset && isFromHome) {
            setScrollClass(''); // Blue navbar
            setShowLogo(false);
        } else if (!showSubmenu && currentScroll < lastScroll && currentScroll > offset && isFromHome) {
            setScrollClass('up'); // White navbar - Show
            setShowLogo(true);
        } else if (!showSubmenu && currentScroll > lastScroll && isFromHome) {
            setScrollClass('down'); // White navbar - Hide
            setShowLogo(true);
        }
        setLastScroll(currentScroll);
        };
        document.addEventListener('scroll', onScroll);
        return () => document.removeEventListener('scroll', onScroll); // Clean to avoid memory leak
    }, [showSubmenu, lastScroll, isFromHome])

    useEffect(() => {
        //const { pathname } = location;
        document.body.style = 'background-color: #fffff;;overflow:hidden;';
        setIsFromHome(false);
        setShowLogo(true);
        /*if (pathname !== '/')  {
            document.body.style = 'background-color: #fffff;';
            setIsFromHome(false);
            setShowLogo(true);
        }
        else { 
            document.body.style = 'background-color: #2c1943;overflow:hidden;';
            setIsFromHome(true);
            setShowLogo(false);
        }*/
    }, [location]);

    window.addEventListener('resize', handleResize);

    function handleResize() {
        if (showSubmenu && window.innerWidth >= 768) {
            setShowSubmenu(false);
        }
    }

    function closeSubMenu() {
        setShowSubmenu(false);
    }

    /**
     * Lets add an offset to the smooth scroll behavior
     * @param {*} el 
     */
    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -85;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (
        <>
            <header className={`header${showSubmenu ? ' active' : ''}${path === '/' ? ' header--home-2022' : ''}${isFromHome ? ' header--from-home' : ''}${!isFromHome &&scrollClass ? ' header--from-home--' + scrollClass : ''}`}>
                <div className={`header__container${isFromHome ? ' header__container--from-home' : ''}${isFromHome && showLogo ? ' header__container--from-home--logo' : ''}`}>
                    {showLogo && (
                        <Link to="/" className={`${isFromHome ? 'opacity-logo' : ''}`}>
                            <svg className={`header__container__logo${path === '/' ? ' header__container__logo--home-2022' : ''}`} viewBox="0 0 460 130">
                                {path === '/' ? <LogoRemoteEdition /> : <Logo />}
                            </svg>
                        </Link>
                    )}
                    <div
                        className="header__container__hamburguer"
                        onClick={() => { setShowSubmenu(!showSubmenu) }}>
                        <div className="bar__hide"></div>
                    </div>

                    <div className="header__container__menu">
                        {location.pathname !== '/' && (
                            <>
                                <div className="header__container__menu__item">
                                    {!isFromHome && <HashLink className="header__container__menu__item__txt" smooth to={`${path}#speakers`} scroll={el => scrollWidthOffset(el)}>Speakers</HashLink>}
                                </div>
                                <div className="header__container__menu__item">
                                    {!isFromHome && <HashLink className="header__container__menu__item__txt" smooth to={`${path}#memories`} scroll={el => scrollWidthOffset(el)}>Memories</HashLink>}
                                </div>
                            </>
                        )}
                        <div className="header__container__menu__item header__container__menu__item--dropdown">
                            <div className="header__container__menu__item__txt">Ediciones Anteriores</div>
                            <img
                                className={`header__container__menu__item__arrow ${isFromHome ? 'header__container__menu__item__arrow--from-home' : ''}`}
                                src={path !== '/' ? arrow : arrowHome}
                                alt="Menu"
                            />
                            <ul className={`header__container__menu__item__list${isFromHome ? ' header__container__menu__item__list--from-home': ''}`}>
                                <li className="header__menu-item"><Link to="/edicion-2021">Edición 2021</Link></li>
                                <li className="header__menu-item"><Link to="/edicion-2020">Edición 2020</Link></li>
                                <li className="header__menu-item"><Link to="/edicion-2019">Edición 2019</Link></li>
                                <li className="header__menu-item"><Link to="/edicion-2017">Edición 2017</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <ul className={`header--submenu ${showSubmenu ? 'active' : ''}`} onClick={closeSubMenu}>
                {isFromHome ? (
                    <></>
                ) : (
                    <>
                    <li className="header__menu-item"><HashLink smooth to={`${path}#speakers`} scroll={el => scrollWidthOffset(el)}>Speakers</HashLink></li>
                    <li className="header__menu-item"><HashLink smooth to={`${path}#memories`} scroll={el => scrollWidthOffset(el)}>Memories</HashLink></li>
                    </>
                )}

                <li className="header__menu-item"><Link to="/edicion-2021">Edición 2021</Link></li>
                <li className="header__menu-item"><Link to="/edicion-2020">Edición 2020</Link></li>
                <li className="header__menu-item"><Link to="/edicion-2019">Edición 2019</Link></li>
                <li className="header__menu-item"><Link to="/edicion-2017">Edición 2017</Link></li>
            </ul>
        </>
    )
}