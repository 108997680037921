import React from 'react'
import NextEdition from '../../components/NextEdition/NextEdition'
import line from '../../res/img/prev-editions/line_19.svg'

import spkr_1 from '../../res/img/prev-editions/spkr_gb.png'
import spkr_2 from '../../res/img/prev-editions/spkr_fj.png'
import spkr_3 from '../../res/img/prev-editions/spkr_ju.png'
import spkr_4 from '../../res/img/prev-editions/spkr_bg.png'
import spkr_5 from '../../res/img/prev-editions/spkr_gw.png'

import gallery_1 from '../../res/img/prev-editions/2019/2019_1.png'
import gallery_2 from '../../res/img/prev-editions/2019/2019_2.png'
import gallery_3 from '../../res/img/prev-editions/2019/2019_3.png'
import gallery_4 from '../../res/img/prev-editions/2019/2019_4.png'
import gallery_5 from '../../res/img/prev-editions/2019/2019_5.png'
import gallery_6 from '../../res/img/prev-editions/2019/2019_6.png'
import gallery_7 from '../../res/img/prev-editions/2019/2019_7.png'
import gallery_8 from '../../res/img/prev-editions/2019/2019_8.png'
import gallery_9 from '../../res/img/prev-editions/2019/2019_9.png'
import gallery_10 from '../../res/img/prev-editions/2019/2019_10.png'
import gallery_11 from '../../res/img/prev-editions/2019/2019_11.png'
import gallery_12 from '../../res/img/prev-editions/2019/2019_12.png'

export default function Nineteen() {

    const speakers = [
        { name: "Gustavo Brey", job: "Ingenia CA Co-Founder & CGO", img: spkr_1 },
        { name: "Federico Jack", job: "VP of Operations at Satellogic", img: spkr_2 },
        { name: "Juan Umaran", job: "CBO at Arbusta", img: spkr_3 },
        { name: "Berina García", job: "UX Lead. Design Sprint Master", img: spkr_4 },
        { name: "Gustavo Walter", job: " Jefe de Sistemas en Editorial Rio Negro", img: spkr_5 },
    ];

    const imgFiles = [
        { img: gallery_1 },
        { img: gallery_2 },
        { img: gallery_3 },
        { img: gallery_4 },
        { img: gallery_5 },
        { img: gallery_6 },
        { img: gallery_7 },
        { img: gallery_8 },
        { img: gallery_9 },
        { img: gallery_10 },
        { img: gallery_11 },
        { img: gallery_12 }
    ];

    return (
        <>
            <NextEdition
                actualEdition={{ edition: '2019', color: 'orange' }}
                changeToEdition={{ edition: '2020', color: 'blue' }}
                title={{ regular: "Emprender en", highlight: 'Tecnología', img: line }}
                speakers={speakers}
                gallery={imgFiles}
            />
        </>
    )
}
