import React from 'react'
import NextEdition from '../../components/NextEdition/NextEdition'
import line from '../../res/img/prev-editions/line_17.png'

import spkr_1 from '../../res/img/prev-editions/spkr_sb.png'
import spkr_2 from '../../res/img/prev-editions/spkr_gb.png'
import spkr_3 from '../../res/img/prev-editions/spkr_rm.png'
import spkr_4 from '../../res/img/prev-editions/spkr_shc.png'
import spkr_5 from '../../res/img/prev-editions/spkr_rb.png'

import gallery_1 from '../../res/img/prev-editions/2017/2017_1.png'
import gallery_2 from '../../res/img/prev-editions/2017/2017_2.png'
import gallery_3 from '../../res/img/prev-editions/2017/2017_3.png'
import gallery_4 from '../../res/img/prev-editions/2017/2017_4.png'
import gallery_5 from '../../res/img/prev-editions/2017/2017_5.png'
import gallery_6 from '../../res/img/prev-editions/2017/2017_6.png'
import gallery_7 from '../../res/img/prev-editions/2017/2017_7.png'
import gallery_8 from '../../res/img/prev-editions/2017/2017_8.png'
import gallery_9 from '../../res/img/prev-editions/2017/2017_9.png'
import gallery_10 from '../../res/img/prev-editions/2017/2017_10.png'
import gallery_11 from '../../res/img/prev-editions/2017/2017_11.png'
import gallery_12 from '../../res/img/prev-editions/2017/2017_12.png'

export default function Seventeen() {
    const speakers = [
        { name: "Santiago Blanco", job: "IT Architecture Manager de Cablevision Fibertel", img: spkr_1 },
        { name: "Gustavo Brey", job: "Genente de Sistemas de Aplicaciones de Swiss Medical Group", img: spkr_2 },
        { name: "Ricardo Markiewicz", job: "VP of Engineering de Patagonian Tech", img: spkr_3 },
        { name: "Sebastián Herrera Cano", job: "Mobile & Web Developer, Entrepreneur de GDG Neuquén Manager", img: spkr_4 },
        { name: "Rudi Borrmann", job: "Subsecretario de Innovación Pública de Ministerio de Modernización de la Nación", img: spkr_5 },
    ];

    const imgFiles = [
        { img: gallery_1 },
        { img: gallery_2 },
        { img: gallery_3 },
        { img: gallery_4 },
        { img: gallery_5 },
        { img: gallery_6 },
        { img: gallery_7 },
        { img: gallery_8 },
        { img: gallery_9 },
        { img: gallery_10 },
        { img: gallery_11 },
        { img: gallery_12 }
    ];
    return (
        <>
            <NextEdition
                actualEdition={{ edition: '2017', color: 'blue' }}
                changeToEdition={{ edition: '2019', color: 'orange' }}
                title={{ regular: "Arquitecturas it", highlight: 'de misión crítica', img: line }}
                speakers={speakers}
                gallery={imgFiles}
            />
        </>
    )
}
