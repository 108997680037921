import React from 'react'
import './Speaker.scss'
import { ReactComponent as Loading } from '../../res/img/loading.svg'

export default function Speaker(props) {

    const speakerUrl = <div className={`spkr ${props.data.color ? 'spkr--' + props.data.color : ''}${props.data.addClass ? ' spkr--' + props.data.addClass : ''}`}>
        <a href={props.data.url} target="_blank" rel="noopener noreferrer">
            <div className="spkr__img-box">
                <div className="spkr__img-box--wrapper" >
                    <img src={props.data.img} alt={props.data.name} />
                </div>
            </div>
            <div className="spkr__info">
                <h3 className="spkr__info__name">{props.data.name}</h3>
                <div className="spkr__info__job">
                    <p className="spkr__info__job__txt" style={{ marginLeft: props.data.paddingLeft }}>{props.data.job}</p>
                    <img className="spkr__info__job__img" src={props.data.colorBox} alt="" />
                </div>
                <p className="spkr__info__role">{props.data.role}</p>
            </div>
        </a>
    </div>

    const speaker = <div className={`spkr ${props.data.color ? 'spkr--' + props.data.color : ''}${props.data.addClass ? ' spkr--' + props.data.addClass : ''}`}>
        <div className="spkr__img-box">
            <div className="spkr__img-box--wrapper" >
                <img src={props.data.img} alt={props.data.name} />
            </div>
        </div>
        <div className="spkr__info">
            <h3 className="spkr__info__name">{props.data.name}</h3>
            <div className="spkr__info__job">
                <p className="spkr__info__job__txt" style={{ marginLeft: props.data.paddingLeft }}>{props.data.job}</p>
                <img className="spkr__info__job__img" src={props.data.colorBox} alt="" />
            </div>
            <p className="spkr__info__role">{props.data.role}</p>
        </div>
    </div>

    if (props.data.name) {
        return (
            <div className={`spkr ${props.data.color ? 'spkr--' + props.data.color : ''}${props.data.addClass ? ' spkr--' + props.data.addClass : ''}`}>
                {props.data.url ? speakerUrl : speaker}
            </div>
        )
    } else {
        return (
            <div className={`spkr spkr--no-content${props.data.color ? ' spkr--' + props.data.color : ''}${props.data.addClass ? ' spkr--' + props.data.addClass : ''}`}>
                <svg className="spkr__fl-loading">
                    <Loading />
                </svg>
                <p className="spkr__loading">Coming<br />Soon </p>
            </div>
        );
    }
}