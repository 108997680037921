import React from 'react'
import FloatingText from '../FloatingText/FloatingText';
import Speakers from '../Speakers/Speakers'
import OutlineText from '../OutlineText/OutlineText';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../res/img/logo-conf.svg'
import speakersTxt from '../../res/img/prev-editions/speakers.png'
import memoriesTxt from '../../res/img/prev-editions/memories.svg'
import curveBG from '../../res/img/prev-editions/curve.png'
import "./NextEdition.scss"
import Gallery from '../Gallery/Gallery';

export default function NextEdition(props) {
    const hideForm = props.hideForm ? props.hideForm : false;
    return (
        <div className={`page next-ed ${props.actualEdition.color ? 'next-ed--' + props.actualEdition.color : ''}`}>
            <div className="next-ed__header">
                <div className="container">
                    <div className="next-ed__header__actual">
                        Edición
                            <OutlineText
                            text={props.actualEdition.edition}
                            fill={true}
                            viewWidth={38}
                            stroke={0.35}
                            color={props.actualEdition.color}
                        />
                    </div>
                    <Link to={`/edicion-${props.changeToEdition.edition}`} className="next-ed__header__next">
                        Edición
                        <OutlineText
                            text={props.changeToEdition.edition}
                            fill={true}
                            viewWidth={38}
                            stroke={0.35}
                            color={props.changeToEdition.color}
                        />
                    </Link>
                </div>
            </div>

            <div className="next-ed__title">
                <div className="next-ed__title___img-bg">
                    <img src={props.title.img} alt="" />
                </div>
                <div className="next-ed__title__box">
                    <h1 className="next-ed__title__box__content">
                        {props.title.regular}
                        <span className="next-ed__title__box__content__hglg">
                            {props.title.highlight}
                        </span>
                    </h1>
                </div>
            </div>

            {/* Speakers */}
            <div className="next-ed__spkr">
                <Speakers list={props.speakers} viewMode="basic">
                    <FloatingText times={3} img={speakersTxt} altText="Speakers" />
                </Speakers>
            </div>

            {props.gallery && (
                <div className="next-ed__gallery" id="memories">
                    <FloatingText times={3} img={memoriesTxt} altText="Memories" />
                    <Gallery imgList={props.gallery} />
                </div>
            )}

            {!hideForm && (
                <div className="next-ed__footer">
                    <div className="next-ed__footer__fl-bg">
                        <img className="next-ed__footer__fl-bg__fl-img" src={curveBG} alt="" />
                    </div>
                    <ul className="next-ed__footer__list container">
                        <li className="next-ed__footer__list__item next-ed__footer__list__item--info">
                            Edición <br />
                            <OutlineText
                                text="2020"
                                fill={true}
                                viewWidth={38}
                                stroke={0.3}
                            /><br />
                            19 DE NOVIEMBRE <span className="item__date">- 10 hs</span>
                        </li>
                        <li className="next-ed__footer__list__item next-ed__footer__list__item--brand">
                            <svg className="item__logo" viewBox="0 0 460 130">
                                <Logo />
                            </svg> <br />
                            La evolución del<br />
                            <OutlineText
                                text="TALENTO"
                                fill={false}
                                viewWidth={73}
                                stroke={0.4}
                                strokeColor="white"
                            />
                        </li>
                    </ul>
                </div>
            )

            }
        </div>
    )
}
