import React from 'react'
import './OutlineText.scss'

export default function OutlineText(props) {
    return (
        <svg className={`out-txt ${props.color ? `out-txt--${props.color}` : ''}${props.strokeColor ? ` out-txt--stroke-${props.strokeColor}` : ''}`} viewBox={`0 0 ${props.viewWidth || 100} 20`}>
            { props.fill && <text className="out-txt__txt out-txt__txt--base" x="0" y="50%" dominantBaseline="central" textAnchor="start">{props.text}</text>}
            <text
                className="out-txt__txt out-txt__txt--outline"
                x="0.6" y="50%"
                dominantBaseline="central"
                textAnchor="start"
                style={{ strokeWidth: `${props.stroke || 0.15}` }} >
                {props.text}
            </text>
        </svg>
    )
}
