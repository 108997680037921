import React from 'react';
import ComingSoon from '../ComingSoon/ComingSoon';
import TwentyTwo from '../TwentyTwo/TwentyTwo';
import './Home.scss'


// Main component
const Home = () => {
  const isComingSoon = false;
  return isComingSoon ? (
    <div className="home__main">
      <ComingSoon />
    </div>
  ) : (
    <TwentyTwo />
  );
};

export default Home;
