import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import './FooterBrand.scss'
import logo from '../../res/img/logo.svg'
import comingSoonLogo from '../../res/img/coming-soon/logo-footer.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

export default function FooterBrand() {
    const location = useLocation();
    const [isFromHome, setIsFromHome] = useState(true);

    useEffect(() => {
        const { pathname } = location;
        if (!['/', '/edicion-2021'].includes(pathname)) setIsFromHome(false);
        else setIsFromHome(true);
    }, [location]);

    return !isFromHome ? (
        <div className={`footer-brand${isFromHome ? ' footer-brand--from-home' : ''}`}>
            <div className="container">
                <div className="footer-brand__content">
                    Powered by
                    <a className="footer-brand__content__link" href="https://patagonian.com/" target="_blank" rel="noopener noreferrer">
                        <img
                            className="footer-brand__content__link__logo"
                            alt="Patagonian"
                            src={isFromHome ? comingSoonLogo : logo}
                        />
                    </a>
                </div>
                <ul className="footer-brand__social">
                    <li className="footer-brand__social__item">
                        <a className="footer-brand__social__item__link footer-brand__social__item__link--facebook" href="https://www.facebook.com/patagoniantech" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                    </li>
                    <li className="footer-brand__social__item">
                        <a className="footer-brand__social__item__link" href="https://www.linkedin.com/company/patagonian/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                    </li>
                    <li className="footer-brand__social__item">
                        <a className="footer-brand__social__item__link" href="https://www.instagram.com/patagoniantech/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </li>
                    <li className="footer-brand__social__item">
                        <a className="footer-brand__social__item__link" href="https://twitter.com/patagoniantech" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    ) : null;
}








