import React from 'react'
import Countdown from 'react-countdown';
//  SwiperJS
import { Swiper, SwiperSlide } from 'swiper/react';
import './TwentyOne.scss'
//  Images
import argentinaImage from '../../res/img/conf-2021/argentina.png';
import colombiaImage from '../../res/img/conf-2021/colombia.png';
import comingSoonLogo from '../../res/img/coming-soon/logo-footer.svg';
import clickImage from '../../res/img/conf-2021/click.svg';
import headerImage from '../../res/img/conf-2021/main-image.png';
import laptopImage from '../../res/img/conf-2021/laptop.png';
import lineupImage from '../../res/img/conf-2021/lineup.png';
import joystickImage from '../../res/img/conf-2021/joystick.png';
import mainLogo from '../../res/img/coming-soon/Logo.png';
import memoriesTextImage from '../../res/img/conf-2021/memories.svg';
import microphoneImage from '../../res/img/conf-2021/microphone.png';
import plusImage from '../../res/img/conf-2021/plus.png';
import speakersTextImage from '../../res/img/conf-2021/speakers.svg';
import sponsorsTextImage from '../../res/img/conf-2021/sponsors.png';

//Circle images
import firstCircle from '../../res/img/conf-2021/circle-1.png';
import secondCircle from '../../res/img/conf-2021/circle-2.png';
import thirdCircle from '../../res/img/conf-2021/circle-3.png';
import fourthCircle from '../../res/img/conf-2021/circle-4.png';
import fifthCircle from '../../res/img/conf-2021/circle-5.png';
import sixthCircle from '../../res/img/conf-2021/circle-6.png';
import seventhCircle from '../../res/img/conf-2021/circle-7.png';
//Speaker images
import firstSpeaker from '../../res/img/conf-2021/speakers/martina-rua.png';
import secondSpeaker from '../../res/img/conf-2021/speakers/rodrigo-falco.png';
import thirdSpeaker from '../../res/img/conf-2021/speakers/santiago-blanco.png';
import fourthSpeaker from '../../res/img/conf-2021/speakers/damian-hernaez.png';
import fifthSpeaker from '../../res/img/conf-2021/speakers/adrian-mastronardi.png';
import sixthSpeaker from '../../res/img/conf-2021/speakers/federico-jack.png';
import seventhSpeaker from '../../res/img/conf-2021/speakers/brayan-barrios.png';
import eightthSpeaker from '../../res/img/conf-2021/speakers/ernesto-van-peborgh.png';
import ninethSpeaker from '../../res/img/conf-2021/speakers/hanna-schiuma.png';
import tenthSpeaker from '../../res/img/conf-2021/speakers/alejandro-gomez.png';
// Sponsor images
import firstSponsor from '../../res/img/conf-2021/sponsors/ingenia.png';
import secondSponsor from '../../res/img/conf-2021/sponsors/ank.png';
import thirdSponsor from '../../res/img/conf-2021/sponsors/grupo_prima.png';
import fourthSponsor from '../../res/img/conf-2021/sponsors/rio_negro.png';
import fifthSponsor from '../../res/img/conf-2021/sponsors/invap.png';
import sixthSponsor from '../../res/img/conf-2021/sponsors/inkasur.png';

/**
 * LINE UP TIME LINE INFO LIST
 */
const LINEUP_ITEMS = [
  { title: 'Rodrigo Falcó', helpText: `#TechUpdate #What’sNext #Trends`},
  { title: 'Alejandro Gómez Montoya', helpText: '#ArtificialIntelligence #What’sNext  #AIStateofMind'},
  { title: 'Santiago Blanco', helpText: '#Blockchain #Data #Trends'},
  { title: 'Hanna Schiuma', helpText: '#crypto #currency #fintech'},
  { title: 'Damián Hernaez', helpText: '#gaming #development #trends'},
  { title: 'Ernesto Van Peborgh', helpText: '#sustainability #involvement #technology'},
  { title: 'Adrian Mastronardi · Federico Jack · Brayan Barrios', helpText: '#ArtificialIntelligence #What’sNext  #AIStateofMind'},
]

/**
 * MEMORIES LIST
 */
const MEMORIES = [
  { url: 'qjrEE0I7yJQ', alt: 'video' },
]

/**
 * SPONSORS INFO LIST
 */
 const SPONSORS = [
  { img: firstSponsor, name: 'Ingenia', url: 'https://ingenia.la/' },
  { img: secondSponsor, name: 'ank', url: 'http://patagonianconf.com/edicion-2021' },
  { img: thirdSponsor, name: 'grupo prima', url: 'https://grupoprima.com/' },
  { img: fourthSponsor, name: 'río negro', url: 'https://www.rionegro.com.ar/' },
  { img: fifthSponsor, name: 'invap', url: 'https://www.invap.com.ar/' },
  { img: sixthSponsor, name: 'inkasur', url: 'https://www.inkasur.com.ar/' },
];

/**
 * SPEAKERS INFO LIST
 */
const SPEAKERS = [
  { img: secondSpeaker, name: 'RODRIGO FALCÓ', description: 'CTO · Patagonian'},
  { img: thirdSpeaker, name: 'SANTIAGO BLANCO', description: 'CTO · Ingenia'},
  { img: fourthSpeaker, name: 'DAMIAN HERNAEZ', description: 'Development Director · Tlön Industries'},
  { img: fifthSpeaker, name: 'ADRIÁN MASTRONARDI', description: 'CTO · RappiPay'},
  { img: sixthSpeaker, name: 'FEDERICO JACK', description: 'Startup Advisor · Semtive'},
  { img: seventhSpeaker, name: 'BRAYAN BARRIOS', description: 'Software architect · Patagonian'},
  { img: eightthSpeaker, name: 'ERNESTO VAN PEBORGH', description: 'Director · Capital Institute'},
  { img: ninethSpeaker, name: 'HANNA SCHIUMA', description: 'VP Wealth & Community · ank'},
  { img: tenthSpeaker, name: 'ALEJANDRO GOMEZ MONTOYA', description: 'Head of AI · DAPI'},
];

/**
 * Images after conf footer container
 */
 const AfterConfFooter = () => {
  return (
    <div className="after-conf-footer__main">
      <img src={joystickImage} className="after-conf-footer__image--first" alt="Laptop" />
      <img className="after-conf-footer__image--second" src={plusImage} alt="plus" />
    </div>
  );
};

// Random component
const Completionist = () => <></>;

// Renderer countdown timer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
        <div className="countdown__main">
            <div className="countdown__item">
                <p className="countdown__number">{days}</p>
                <p className="countdown__text">DÍAS</p>
            </div>
            <p className="colon">:</p>
            <div className="countdown__item">
                <p className="countdown__number">{hours}</p>
                <p className="countdown__text">HORAS</p>
            </div>
            <p className="colon">:</p>
            <div className="countdown__item">
                <p className="countdown__number">{minutes}</p>
                <p className="countdown__text">MINUTOS</p>
            </div>
            <p className="colon">:</p>
            <div className="countdown__item">
                <p className="countdown__number">{seconds}</p>
                <p className="countdown__text">SEGUNDOS</p>
            </div>
        </div>
    );
  }
};

/**
 * Header component of the conf
 */
const Header = ({ isAfterConf }) => {
  const buttonOnClick = () => {
    const el = document.getElementById('hubspot__form')
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -85;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  return (
    <div className="header__main header__main--after-conf">
      <img className="home__logo__img--first" src={mainLogo} alt="Patagonian logo" />
      <div className="header__main__content header__main__content--after-conf">
          <div className="header__main__left-side">
            <div className="header__main__title">
              <img className="home__logo__img--second home__logo__img--second--after-conf" src={clickImage} alt="Patagonian logo" />
              <p className="header__main__title--section">AL FUTURO</p>
            </div>
            {!isAfterConf && (
              <div className="header__main__countries">
                <div className="header__main__countries__item">
                  <p className="header__main__countries__label">18 NOVIEMBRE · 9:00 AM</p>
                  <img className="header__main__countries__img" src={colombiaImage} alt="Colombia" />
                </div>
                <div className="header__main__countries__item">
                  <p className="header__main__countries__label">11:00 AM</p>
                  <img className="header__main__countries__img" src={argentinaImage} alt="Argentina" />
                </div>
              </div>
            )}
          </div>
          <div className="header__main__right-side">
              <img className="header__main__img header__main__img--after-conf" src={headerImage} alt="click al futuro" />
          </div>
      </div>
      {!isAfterConf && (
        <>
          <div className="countdown_timer__main">
            <Countdown
              date={new Date('November 18, 2021 9:00:00')}
              renderer={renderer}
            />
          </div>
          <div className="button__main">
            <div className="button__container" onClick={buttonOnClick}>
              <p className="button__label">REGISTRATE AHORA</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

/**
 * Footer component of the conf
 */
const Footer = () => {
    return (
        <div className="footer-brand footer-brand--from-home">
          <div className="container">
            <div className="footer-brand__content">
              <p>Powered by</p>
              <a className="footer-brand__content__link" href="https://patagonian.com/" target="_blank" rel="noopener noreferrer">
                <img
                  className="footer-brand__content__link__logo"
                  alt="Patagonian"
                  src={comingSoonLogo}
                />
              </a>
            </div>
          </div>
        </div>
    )
};

/**
 * Line up main container
 * @returns the line up main container rendered
 */
const LineUpContainer = () => {

  return (
    <div className="lineup__container__main" id="lineup">
      <div className="lineup__main__content">
        <img className="circle__header circle__header--seventh" src={seventhCircle} alt="seventhCircle cirlce" />
        <div className="lineup__main__images">
          <img src={lineupImage} className="lineup__image--first" alt="Sponsors text" />
          <img src={laptopImage} className="lineup__image--second" alt="Laptop" />
        </div>
        <div className="lineup__main">
          <div className="lineup__description__main">
            <div className="lineup__description__text">
              Este año el encuentro se realizará de manera remota, en vivo, en una transmisión de acceso gratuito. La misión es la de siempre: Aprender.  
            </div>
          </div>
          <div className="lineup__timeline__main">
            <img className="lineup__timeline__plus_img" src={plusImage} alt="plus" />
            {LINEUP_ITEMS.map(({ title, helpText }, index) => (
              <LineUpItem helpText={helpText} key={index} title={title} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Line up item
 * @param helpText description of the lineup item
 * @param title title of the lineup item
 * @returns the line up item component
 */
const LineUpItem = ({
  helpText,
  title,
}) => (
  <div className="lineup__timeline__item">
    <p className="lineup__item__title">{title}</p>
    <p className="lineup__item__help_text">{helpText}</p>
  </div>
);

/**
 * Main container of memories section
 */
const MemoriesContainer = ({ isAfterConf }) => {
  return (
    <div className="speakers__main" id="memories">
      <div className="speakers__content">
        <div className="speakers__main__images">
          <img src={memoriesTextImage} className="memories__image--first" alt="Sponsors text" />
          <img src={laptopImage} className="memories__image--second" alt="Laptop" />
        </div>
        {!isAfterConf && (
          <div className="first_speaker__main">
            <SpeakerAvatar avatar={firstSpeaker} name="MARTINA RUA" description="Host" />
            <p className="description__text description__text--speakers">La edición 2021 será un “Click al futuro”. Dedicaremos el espacio a reflexionar sobre nuevas tecnologías. ¿Cuáles son las tendencias en la industria? ¿Hacia dónde vamos? ¿Qué conocimientos se requieren? ¿Estamos listos para dar el salto?</p>
          </div>
        )}
        <div className="memories__main">
          {MEMORIES.map(({ url, alt }, i) => (
            <div className="memories__main__video" key={i}>
              <iframe src={`https://www.youtube.com/embed/${url}`}
                title={`${alt}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * Speaker avatar item
 * @param avatar speaker image url
 * @param name name of the speaker
 * @param description the short description of the speaker
 * @returns the speaker avatar component
 */
const SpeakerAvatar = ({
  avatar,
  name,
  description,
}) => {
  return (
    <div className="speakers__avatars__item">
        <img className="speakers__avatars__img" src={avatar} alt="speaker" />
        <div className="speakers__avatars__text">
          <p className="speakers__avatars__label">{name}</p>
          <p className="speakers__avatars__description">{description}</p>
        </div>
    </div>
  );
}

/**
 * Main container of speaker section
 */
const SpeakersContainer = ({ isAfterConf }) => {
  return (
    <div className="speakers__main" id="speakers">
      <div className="speakers__content">
        <div className="speakers__main__images">
          <img src={microphoneImage} className="speakers__image--first" alt="Microphone" />
          <img src={speakersTextImage} className="speakers__image--second" alt="Microphone" />
        </div>
        <div className="first_speaker__main">
          <SpeakerAvatar avatar={firstSpeaker} name="MARTINA RUA" description="Host" />
          {!isAfterConf && (
            <p className="description__text description__text--speakers">La edición 2021 será un “Click al futuro”. Dedicaremos el espacio a reflexionar sobre nuevas tecnologías. ¿Cuáles son las tendencias en la industria? ¿Hacia dónde vamos? ¿Qué conocimientos se requieren? ¿Estamos listos para dar el salto?</p>
          )}
        </div>
        <div className="speakers__mobile">
          <Swiper
            loop={true}
            spaceBetween={30}
            slidesPerView={2}
            centeredSlides={true}
            breakpoints={{
                600: {
                    slidesPerView: 3
                }
            }}
          >
            {SPEAKERS && SPEAKERS.map(({ img, name, description }, i) => {
                return (
                  <SwiperSlide key={i}>
                    <SpeakerAvatar avatar={img} name={name} description={description} />
                  </SwiperSlide>
                );
            })}
        </Swiper>
        </div>
        <div className="speakers__avatars__main">
          {SPEAKERS.map(({ img, name, description }) => (
            <SpeakerAvatar key={name} avatar={img} name={name} description={description} />
          ))}
        </div>
      </div>
    </div>
  );
};

/**
 * Sponsor item component
 */
const SponsorItem = ({
  image,
  name,
  url,
}) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
    < img className={`sponsors__img${name === 'inkasur' ? ' sponsors__img--inkasur' : ''}`} src={image} alt={name} />
    </a>
  );
};

/**
 * Sponsor main component
 */
const SponsorContainer = () => {
  return (
    <div className="sponsors__container__main" id="sponsors">
      <div className="sponsors__content">
        <div className="sponsors__main__images">
          <img src={joystickImage} className="sponsors__image--second" alt="Laptop" />
          <img src={sponsorsTextImage} className="sponsors__image--first" alt="Sponsors text" />
        </div>
        <div className="sponsors__main">
          {SPONSORS.map(({ img, name, url }) => (
            <SponsorItem key={name} image={img} name={name} url={url} />
          ))}
        </div>
      </div>
    </div>
  );
};

// Main component
const TwentyOne = () => {
  const isAfterConf = true;

  return (
    <div className="home">
      <img className="circle__header circle__header--first" src={firstCircle} alt="first circle" />
      <img className="circle__header circle__header--second" src={firstCircle} alt="first cirlce" />
      <img className="circle__header circle__header--third" src={secondCircle} alt="second cirlce" />
      <img className="circle__header circle__header--fourth" src={thirdCircle} alt="second cirlce" />
      <div className="home__background"></div>
      <div className="home__container">
        <Header isAfterConf={isAfterConf} />
        <div className="description__container">
          <div className="description__content">
            <p className="description__text description__text--conf">
              Patagonian Conf es un evento anual que busca divulgar temáticas de tecnología e innovación. Invitamos a especialistas de distintas disciplinas a compartir sus conocimientos y sumar valor a la comunidad tecnológica.  
            </p>
          </div>
        </div>
      </div>
      <div className="empty__space empty__space--first">
        <img className="circle__speakers circle__speakers--first" src={fourthCircle} alt="fourth cirlce" />
        <img className="circle__speakers circle__speakers--second" src={sixthCircle} alt="sixth cirlce" />
      </div>
      <div className="home__container">
        <SpeakersContainer isAfterConf={isAfterConf} />
        {isAfterConf ? (
          <>
          <div className="empty__space empty__space--third"></div>
            <MemoriesContainer isAfterConf={isAfterConf} />
            <AfterConfFooter />
          </>
        ) : (
          <LineUpContainer />
        )}
        {!isAfterConf && (
          <>
            <SponsorContainer />
          </>
        )}
        <div className="empty__space empty__space--second"></div>
        <Footer />
        {!isAfterConf && (
          <img className="circle__footer circle__footer--first" src={fifthCircle} alt="fifth cirlce" />
        )}
      </div>
    </div>
  );
};

export default TwentyOne;
